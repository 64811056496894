/* Contact Section Styling */
.contact-section {
    padding: 50px 0;
    background-color: #fff; /* Blue theme background */
    color: #002366;
    margin-top: 40px;


}

/* .contact-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;

} */

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 20px;
    align-items: stretch; /* Ensure both the form and details stretch to the same height */
}

.contact-form, .contact-details {
    flex: 1; /* Both should take equal space */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



/* Contact Form Styling */
/* .contact-form {
    flex: 1;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #000;
    border: 1px solid #002366;

} */

.contact-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #000;
    border: 1px solid #002366;
}

.contact-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.form-row input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;
}

.submit-btn {
    /* background-color: #002366; */
    background-image: linear-gradient(135deg, #012d58, #004e92);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    width: 100%;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    /* background-color: #114574; */
    background-image: linear-gradient(135deg, #114574, #1a6ba0);
    /* background-color: #e73faf; */
}

/* Contact Details Styling */
/* .contact-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
} */

.contact-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail-item {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #002366;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, padding 0.6s ease;
}

.detail-item:hover {
    background-color: #f0f0f0; /* Slight background color change */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Larger shadow */
    padding: 25px; /* Slight increase in padding */
}


.detail-item:hover h4, .detail-item:hover p {
    color: #114574; /* Change text color */
}


.contact-details .detail-item:last-child {
    margin-bottom: 0; /* Remove extra margin for the last item */
}

.contact-icon {
    font-size: 45px;
    /* color: #002366; */
    color: #004e92;
    margin-right: 20px;
}

.detail-item h4 {
    margin-bottom: 5px;
    font-size: 18px;
}

.detail-item p {
    color: #000;
}


/* Status Message Styling */
.status-message {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}

.success {
    color: darkgreen; /* Success message in Dark Green */
}

.error {
    color: red; /* Error message in Red */
}




/* Responsive Styling */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    .form-row {
        flex-direction: column;
    }
}
