/* Facilities Section Styling */
.facilities-section {
    width: 100%;
    padding: 50px 0;
    /* background-color: #f9f9f9; */


}

.facilities-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

.facilities-title {
    font-size: 2.5rem;
    color: #114574;
    margin-bottom: 20px;
}

.facilities-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.facilities-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.facility-card {
    /* background-color: #fff; */
    /* background: linear-gradient(135deg, #000428, #004e92); */
    background-image: linear-gradient(135deg, #012d58, #004e92);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    padding: 20px;
    transition: transform 0.9s ease;
    text-align: center;
}

.facility-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}



.facility-image {
    width: 100%;
    height: 308px;
    object-fit: contain;
    /* Ensure the full image is displayed */
    border-radius: 5px;
    margin-bottom: 15px;
    /* background: linear-gradient(135deg, #000428, #004e92); */
}

.facility-name {
    font-size: 1.2rem;
    /* color: #114574; */
    color: #fff;
    font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
    .facility-card {
        width: 45%;
    }

    .facility-name {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .facility-card {
        width: 100%;
    }
}