/* Style for the section */
.attraction-cards-section {
    width: 100%;
    padding: 50px 0;
    background-color: #f9f9f9;
    text-align: center;
}

.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
}


/* 
.card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid #004A8C;
    transition: transform 0.3s ease, box-shadow 0.3s ease;;
} */

.card {
    background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.9s ease, box-shadow 0.3s ease; 
    text-align: center;
    position: relative;
}

/* Create a larger hover area */
.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid #004A8C;
    transition: transform 0.9s ease 0.1s, box-shadow 0.3s ease 0.1s; /* Delay added */
}

.card:hover:before {
    content: '';
    position: absolute;
    top: -5px;    /* Extend the hover area */
    left: -5px;   /* Extend the hover area */
    right: -5px;  /* Extend the hover area */
    bottom: -5px; /* Extend the hover area */
    border-radius: 10px;
    border: 1px solid #004A8C; /* Visible border on hover */
    pointer-events: none; /* Prevent pointer events on the hover area */
    transition: border-color 0.3s ease;
}


.icon {
    font-size: 50px;
    margin-bottom: 15px;
    /* Set your desired background color */
    /* background-color: #004B8E;  */
    padding: 15px;
    border-radius: 50%; /* Makes the background a circle */
    display: inline-block;
    /* Ensures the icon color is visible against the background */
    color: rgb(3, 102, 252); 
   
}



.card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #004e92;
}

.card p {
    font-size: 1rem;
    color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between;  */
        /* Ensure even spacing between cards */
         /* Optional: add some spacing between cards */
    }

    .card {
        width: 44%; /* Adjusted to fit 2 cards per row with some spacing */
        margin-bottom: 20px; /* Optional: space between rows */
    }
}


@media (max-width: 425px){
    .card {
        width: 80%; /* Adjusted to fit 1 cards per row with some spacing */
        
    }
}