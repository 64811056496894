/* .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
} */

.navbar {
    position: sticky;
    top: 0;
    width: 100%;
    /* background-image: linear-gradient(to right, #FF512F, #DD2476); */
    /* background-image: linear-gradient(135deg, #0F2027, #203A43); */
    /* background-image: linear-gradient(135deg, #000428, #004e92); */
    background-image: linear-gradient(135deg, #012d58, #004e92);

    /* background-image: linear-gradient(135deg, #0F2027, #203A43, #2C5364, #1C92D2); */
    /* background-image: linear-gradient(135deg, #2C3E50, #4CA1AF); */

    /* background-image: linear-gradient(135deg, #0F2027, #203A43, #2C5364); */

    /* Gradient background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-logo {
    display: flex;
    align-items: center;
}


/* .logo-wrapper {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
} */


.logo-image {
    height: 50px;
    width: auto;
    margin-right: 10px;
    /* filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5)); */
    background-color: white;
    border-radius: 5px;

}

.brand-name {
    font-size: 1.5rem;
    font-weight: bold;
    /* color: #333333; */
    color: #fff;
}

.navbar-links {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    /* Push links to the right on larger screens */
}

.navbar-links li {
    margin-left: 30px;
}

.navbar-links a {
    text-decoration: none;
    /* color: #333333; */
    color: #fff;
    font-size: 1rem;
    transition: color 0.3s ease;
    font-weight: 550;
}

.navbar-links a:hover {
    color: #ffd900;
}

/* Hamburger icon on the right */
.hamburger-icon {
    display: none;
}


/* Responsive Design */
@media (max-width: 768px) {
    .navbar-container {
        flex-direction: row;
        justify-content: space-between;
    }

    /* Hide links initially on mobile */
    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 71px;
        right: 0;
        background-color: #004B8E;
        width: auto;
        min-width: 160px;
        padding: 20px;
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links li {
        margin: 10px 0;
    }

    /* Display the hamburger icon on mobile */
    .hamburger-icon {
        display: block;
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }


    /* Ensure hamburger icon is hidden on larger screens */
    @media (min-width: 769px) {
        .hamburger-icon {
            display: none;
        }

        .navbar-links {
            display: flex;
        }
    }

}



/* Adjust for mobile screens with max-width of 325px */
@media (max-width: 325px) {

    .navbar-links {
        width: auto;          /* Adjusts to fit the content */
        padding: 10px;
        right: 5px;           /* Align dropdown properly */
        min-width: 200px;     /* Set a minimum width to prevent text breaking */
    }

    .navbar-links li {
        margin: 5px 0;
    }

    .navbar-links a {
        font-size: 0.9rem;    /* Reduce font size slightly */
        padding: 5px;         /* Reduce padding to fit better */
        white-space: nowrap;  /* Prevent text from wrapping into a new line */
    }

    /* Ensure "Contact Us" text doesn't break */
    .navbar-links a[href="#contact"] {
        white-space: nowrap;
    }

    .hamburger-icon {
        font-size: 1.5rem;    /* Adjust hamburger icon size */
    }
}
