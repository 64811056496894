/* src/components/HeroBanner.css */

.hero-banner {
    width: 100%;
    min-height: 100vh; /* used min-height instead of height to manage the height in responsive mode as well */;
    /* Full view height */
    /* background: linear-gradient(135deg, #4E65FF, #92EFFD); */

    /* Dark Midnight Blue to Cool Blue */
    background: linear-gradient(135deg, #000428, #004e92); 

    /* Gradient Background */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Adding padding for space when the navbar collapses */
    /* padding-top: 80px;  */
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    /* Add padding for smaller screens */
    /* padding: 0 20px;  */
    box-sizing: border-box;
}

.content {
    color: #ffffff;
    max-width: 600px;
}

.content h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
}

.content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #fceb02;

}

.content h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    background: #114574;
    /* This restricts the background color to the text */
    display: inline-block; 
    /* width: fit-content; */
    padding: 5px 10px; /* Optional padding to space the text inside the background */
    border-radius: 5px; /* Optional, for a rounded corner effect */
}

.content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.hero-button {
    background-color: #ffffff;
    color: #4E65FF;
    padding: 12px 24px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.hero-button:hover {
    /* background-color: #92EFFD; */
    background-color: #114574;
    color: #fff;
    border: 2px solid #fff;
}

.hero-image img {
    width: 550px;
    max-width: 100%;
    height: 400px;
    /* max-height: 100%; */
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    object-fit: cover;
}




/* For devices with max-width 1024px */
@media (max-width: 1024px){
    .content {
        margin-left: 40px;
        margin-right: 20px;
    }

    .content h1 {
        font-size: 3rem;
    }

    .hero-image img {
        width: 500px;
        /* padding-right: 20px; */
        max-width: 90%;
        height: 350px; 
        /* max-height: 100%;
        border-radius: 10px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
    }


}




/* Responsive Design */
@media (max-width: 768px) {

    .hero-banner{
        /* Let height adjust based on content */
        min-height: auto;
        /* Add padding to prevent overlap */
        padding: 20px 0;
    }
    .content-wrapper {
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }

    .content h1 {
        font-size: 2.2rem; /* Reduce the heading size */
        margin-bottom: 10px;
    }

    .content h2 {
        font-size: 1.8rem; /* Reduce sub-heading size */
    }

    .content h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .content p {
        font-size: 1rem; /* Reduce paragraph size */
        margin-bottom: 20px;
    }

    .hero-image img {
        margin-top: 30px;
        width: 400px !important;
        /* Adjust image size for smaller screens */
        /* height: 250px !important;  */
        height: auto; /* Ensure the height adjusts based on width */
    }

    .hero-button {
        padding: 10px 20px; /* Adjust button size */
    }
}


@media (max-width: 425px) {
    .hero-banner{
        padding-top: 40px;
    }

    .content h1 {
        font-size: 1.8rem; /* Reduce the heading size */
        margin-bottom: 10px;
    }

}