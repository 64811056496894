/* Gallery Section Styling */
.gallery-section {
    width: 100%;
    padding: 50px 0;
    background-image: linear-gradient(135deg, #012d58, #004e92);
    color: white;
    text-align: center;

}

.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    padding: 40px 20px;
}

.gallery-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: #fff;
    padding-bottom: 20px;
}

/* Grid Layout with 3 images per row */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 3 columns of 300px each */
    gap: 15px;
}

.gallery-item {
    position: relative;
    cursor: pointer;
}

.gallery-image {
    width: 100%;
    height: 250px; /* Fixed height to ensure consistency */
    object-fit: cover; /* Maintain aspect ratio while filling the container */
    border-radius: 10px;
    transition: transform 0.9s ease, box-shadow 0.3s ease;
    border: 1px solid #fff;
}

.gallery-item:hover .gallery-image {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Fullscreen Modal Styling */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.full-image {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 3rem;
    color: white;
    cursor: pointer;
}

.prev-button {
    left: 20px;
}

.next-button {
    right: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* 2 columns for smaller screens */
    }
    .full-image {
        max-width: 95%;
        max-height: 70%;
    }
}

@media (max-width: 375px){
    .gallery-title {
        font-size: 2.2rem;
    }
}
