/* Footer Container */
.footer {
    background-color: #003366;
    /* Light blue background */
    padding-top: 40px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
    padding-bottom: 50px;
}

.footer-section {
    flex: 1;
    /* Flexible space for equal sizing */
    min-width: 220px;
    /* Ensure some minimum size */
    color: #fff;
    

}

.footer-logo {
    flex: 1.5;
    /* Slightly larger width for logo and content */
}

.footer-logo-img {
    /* width: 150px; */
    height: 50px;
    width: auto;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 5px;
}

.footer-logo h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
}

.footer-section p {
    font-size: 16px;
    color: #fff;
    margin-right: 58px;
    
}

.footer-links h3,
.footer-social h3 {
    font-size: 18px;
    margin-bottom: 10px;
    /* background: #469de9; */
    /* background: #5c97ca; */
    /* display: inline-block; */
    /* padding: 3px; */
    /* border-radius: 5px; */
}

.footer-links ul,
.footer-social .social-icons {
    list-style-type: none;
    padding: 0;
}

.footer-links ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
}

.footer-links ul li a:hover {
    color: #ffd900;
    /* Link hover color */
}

.footer-social .social-icons a {
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
}

.footer-social .social-icons a:hover {
    color: #ffd900;
}

/* Footer Bar */
.footer-bar {
    /* background-color: #004B8E; */
    background-image: linear-gradient(135deg, #012d58, #004e92);
    /* Darker blue background */
    color: #fff;
    padding: 15px 0;

    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: relative; /* Ensure it appears above other content */
}

.footer-bar-container {
    max-width: 1200px;
    margin: 5px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-bar p {
    margin: 0;
}

.footer-bar .footer-left {
    font-size: 14px;
}

.footer-bar .footer-right {
    font-size: 14px;
}

.footer-bar .footer-right a {
    color: #fff;
    text-decoration: underline;
}

.footer-bar .footer-right a:hover {
    color: #ffd900;
}

/* Remove bottom space */
footer {
    margin-bottom: 0;
    padding-bottom: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-bar-container {
        flex-direction: column;
        text-align: center;
    }

    .footer-section {
        text-align: center;
        margin-bottom: 20px;
    }
}

@media (max-width: 1024px){
    .footer-container{
        padding-left: 30px;
        padding-right: 30px;

    }

    .footer-bar-container{
        padding-left: 30px;
        padding-right: 30px;
    }
}