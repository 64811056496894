

/* General styling */
.reviews-section {
    width: 100%;
    padding: 50px 0;
    /* background-color: #003366;  */
    background-image: linear-gradient(135deg, #012d58, #004e92);
    
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    position: relative;
}

.reviews-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative; /* Added relative positioning for this section */
}

.text-section small {
    font-size: 1.3rem;
    color: #ddd;
    line-height: 2;
}

.text-section small::after {
    content: '';
    display: inline-block;
    background-color: #f8b400;
    width: 20px; /* Increase the width to match the image */
    height: 3px;
    margin-left: 10px;
    vertical-align: middle; /* Aligns the line with the text */
    transform: translateY(2px); /* Adjust the positioning if necessary */

}


.text-section h2 {
    font-size: 3rem;
    color: #fff;
    
}

.arrow-section {
    display: flex;
    gap: 10px;
}


.arrow-section {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 60px; /* Adjust this value to move the icons above the second card */
    right: 110px; /* Adjust this value to move the icons horizontally */
    z-index: 1; /* Ensure the arrows are above the review cards */
}



.arrow {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.arrow:hover {
    transform: scale(1.1);
}

/* Review wrapper */
.reviews-wrapper {
    overflow: hidden;
    width: 100%;
    display: flex; /* Ensure horizontal layout */
    justify-content: center; /* Center the two visible cards */
}

.review-card {
    display: flex;
    transition: transform 0.9s ease-in-out;
    /* transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out, scale 0.9s ease-in-out; */
}

.review {
    background-color: #004B8E;
    color: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 45%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    position: relative;

    
}





.review p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.reviewer-info {
    display: flex;
    align-items: center; /* Center align image and text */
    gap: 10px;
}

.reviewer-info img {
    width: 65px; /* Adjust size */
    height: 65px; /* Adjust size */
    border-radius: 50%;
    object-fit: cover;
}

.reviewer-info h3 {
    font-size: 1.25rem;
    color: #fff;
    text-transform: capitalize;
}

.reviewer-info small {
    font-size: 0.875rem;
    color: #ddd;
}



.quote-icon {
    font-size: 6rem; /* Adjust size as needed */
    color: #f8b400; /* Adjust color to your preference */
    position: absolute; /* Positioned absolutely to allow flexibility */
}

.quote-icon span {
    font-family: Arial, sans-serif; /* Adjust font to match design */
}

.review {
    position: relative;
}

/* 
.review .quote-icon:first-of-type {
    position: absolute;
    top: -10px; 
    left: 20px; 
}

*/


.review .quote-icon:last-of-type {
    bottom: -73px; /* Adjust this to move it outside but above the bottom edge */
    right: 50px; /* Adjust horizontal position */
}





/* Responsive Design */
@media (max-width: 768px) {
    .review {
        width: 100%; /* Show one review at a time on smaller screens */
    }

    .reviews-wrapper {
        padding: 0;
    }

    .reviews-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .arrow-section {
        margin-top: 10px;
        top: unset; /* Remove top positioning for mobile */
        right: unset; /* Remove right positioning for mobile */
        bottom: 10px; /* Move arrows to the bottom of the screen on smaller devices */
        left: 92%; /* Center the arrows on small screens */
        transform: translateX(-50%);
    }


}


@media (max-width: 425px) {

    /* Hide the second card completely on mobile */
    .review:nth-child(2) {
        display: none; /* Hide second card on smaller screens */
    }

    /* Reduce the image size for better proportion on mobile */
    .reviewer-info img {
        width: 50px;
        height: 50px;
    }

}


@media (max-width: 375px){
    .text-section h2 {
        font-size: 2.5rem;
    }
}


