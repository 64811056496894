/* Section container */
.who-can-join-section {
    width: 100%;
    padding: 50px 0;
    background-color: #f0f0f0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Section heading */
.section-heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #003366;
}

/* Cards grid layout */
.cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

/* Individual card styling */
.join-card {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.9s, box-shadow 0.3s;
}

.join-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.card-image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
}

.card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-heading {
    font-size: 1.5rem;
    color: #003366;
    margin-bottom: 10px;
}

.card-description {
    font-size: 1rem;
    color: #666;
}

/* Hover effect */
.join-card:hover .card-content {
    background-color: #003366;
    transition: transform 0.9s ease;
}

.join-card:hover .card-heading,
.join-card:hover .card-description {
    color: #fff;
}

/* Many more text */
.many-more-text {
    text-align: center;
    font-size: 1.5rem;
    color: #003366;
    margin: 40px 0 10px;
}

.many-more-description {
    text-align: center;
    font-size: 1.1rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
}

/* Responsive design */
@media (max-width: 768px) {
    .section-heading {
        font-size: 2rem;
    }

    .join-card {
        flex-direction: column;
    }

    .card-image img {
        width: 100%;
        height: 200px;
    }

    .card-content {
        padding: 20px;
    }
}
