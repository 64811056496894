/* Membership Section Styling */
.membership-section {
    width: 100%;
    padding: 50px 0;
    background-color: #f9f9f9;
    position: relative;
    margin-bottom: 50px;
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to the section itself */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners for a smooth cut effect */
    overflow: visible; /* Allow pseudo-element shadow visibility */
}

/* Pseudo-element for the "cut" and raised look */
.membership-section::after {
    content: '';
    position: absolute;
    bottom: -20px; /* Move it slightly down */
    left: 50%;
    transform: translateX(-50%);
    width: 80%; /* Adjust width to suit the design */
    height: 40px; /* Height of the raised paper */
    /* background-color: #f9f9f9; Same color as the section to blend */
    /* box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.2); Shadow to give the raised effect */
    border-radius: 10px; /* Soften the edges for a "cut" look */
    z-index: -1; /* Keep it behind the section */
}


/* Membership Container */
.membership-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    }


/* Title and Description */
.membership-title {
    text-align: center;
    font-size: 2.5rem;
    color: #114574; /* Primary color */
    margin-bottom: 20px;
}

.membership-description {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
}

/* Tab Buttons */
.tab-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
}

.tab-button {
    background-color: #004A8C; /* Dark Blue */
    color: #fff;
    padding: 12px 24px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tab-button.active,
.tab-button:hover {
    background-color: #114574; /* Lighter blue */
    background-image: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
    
}

/* Table Styling */
.membership-table {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-collapse: collapse;
    margin-bottom: 40px;
    border: 1px solid #114574;
}

.membership-table th,
.membership-table td {
    padding: 12px 20px;
    text-align: left;
    font-size: 1.1rem;
    border-bottom: 1px dashed #aaa;
}

/* Price column dashed line */
.membership-table th:nth-child(2), /* Targeting the 'Price' column in the header */
.membership-table td:nth-child(2) { /* Targeting the 'Price' column in the rows */
    border-left: 1px dashed #aaa;
}

/* Adding dashed line after Price column as well */
.membership-table th:nth-child(2),
.membership-table td:nth-child(2) {
    border-right: 1px dashed #aaa;
}

.membership-table th {
    background-color: #004A8C; /* Header background */
    color: #fff;
}

.membership-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row background */
}


/* Alternate row background colors */
.membership-table tr:nth-child(odd) {
    background-color: #f2f7ff; /* Light blue for odd rows */
}

.membership-table tr:nth-child(even) {
    background-color: #e6eefc; /* Slightly darker blue for even rows */
}



/* Responsive Design */
@media (max-width: 768px) {
    .membership-title {
        font-size: 2rem;
    }

    .membership-description {
        font-size: 1rem;
    }

    .tab-button {
        font-size: 0.9rem;
        padding: 10px 18px;
    }

    .membership-table th,
    .membership-table td {
        padding: 10px;
        font-size: 1rem;
    }
}
