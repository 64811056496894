.guidelines-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
}

.guidelines-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    padding: 60px 20px;

}

.guidelines-left {
    flex: 1;
    padding-right: 20px;
}

.guidelines-title {
    font-size: 3rem;
    color: #003366;
    margin-bottom: 20px;
}

.guidelines-list {
    list-style: none;
    padding: 0;
    margin-left: 27px;
}

.guidelines-list li {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    margin-bottom: 10px;
    color: #555;
}

.tick-icon {
    /* color: #f39c12; */
    color: #004B8E;
    margin-right: 10px;
}

.library-timing {
    margin-top: 20px;
    font-style: italic;
    color: #004B8E;
    font-weight: 800;
}

.guidelines-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guidelines-image {
    max-width: 100%;
    border-radius: 8px;
    height: 400px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .guidelines-container {
        flex-direction: column;
    }
    .guidelines-left {
        padding-right: 0;
        margin-bottom: 20px;
    }
}


@media (max-width: 375px){
    .guidelines-title {
        font-size: 2rem;
    }
}
