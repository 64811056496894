/* About Us Section Styling */

.about-us-section {
    padding: 60px 0;
    /* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
    /* background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); */
    /* background-image: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%); */
    background-image: linear-gradient(135deg, #012d58, #004e92);
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Align content to the top */
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    flex-wrap: wrap;
}

.about-us-text {
    flex: 1;
    padding-right: 20px;
}

.about-us-text small {
    font-size: 1.3rem;
    color: #ddd;
    line-height: 2;
}

.about-us-text small::after {
    content: '';
    display: inline-block;
    background-color: #f8b400;
    width: 20px; /* Increase the width to match the image */
    height: 3px;
    margin-left: 10px;
    vertical-align: middle; /* Aligns the line with the text */
    transform: translateY(0px); /* Adjust the positioning if necessary */

}

.about-us-text h1 {
    font-size: 2.5rem;
    /* color: #333; */
    color: #fff;
    margin-bottom: 20px;
}

.about-us-text h1 .highlight {
    /* Highlight color for 'Solution' */
    color: #004B8E;
}

.about-us-text h2 {
    font-size: 1.75rem;
    /* color: #004B8E; */
    color: #fff;
    background: #469de9;
    display: inline-block;
    margin-bottom: 20px;
}

.about-us-text p {
    font-size: 1.05rem;
    line-height: 1.8;
    /* color: #555;  */
    color: #fff;
    margin-bottom: 20px;
}

/* Adjusting the layout for images */

.about-us-images {
    position: relative;
    flex: 1;
    min-height: 400px;
}

.about-us-image {
    position: absolute;
}

.about-us-image img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #fff;
}

.about-us-image:first-child {
    top: 0;
    left: 0;
    width: 50%;
    transform: translateX(25px);
    /* Move this image slightly towards the right */
}

.about-us-image:last-child {
    bottom: 0;
    right: 0;
    width: 50%;
    transform: translateX(28px);
    /* transform: translateX(-25px); */
    /* Move this image slightly towards the left */
}




@media (max-width: 1024px) {


    /* Adjusting the first image */
    .about-us-image:first-child {
        top: 0;
        left: 0;
        width: 49%;
        transform: translateX(0);
        /* Reset the transform */
        margin-bottom: 20px;
        /* Add spacing below the first image */
    }

    /* Adjusting the second image */
    .about-us-image:last-child {
        bottom: 0;
        right: 0;
        width: 49%;
        
        /* Move it down and slightly to the right */
        transform: translate(0, 50px);

    }


}


@media (max-width: 768px) {
    @media (max-width: 768px) {
        .about-us-container {
            flex-direction: column;
            text-align: center;
        }
    
        .about-us-text {
            padding-right: 0;
            margin-bottom: 20px;
        }
    
        .about-us-images {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            /* gap: 20px; */
        }
    
        /* Remove absolute positioning for the images on small screens */
        .about-us-image {
            position: relative;
            width: 80%; /* Adjust this value as necessary for image width */
            margin-bottom: 20px;
            transform: none; /* Remove transformations */
        }
    
        /* Ensure images are centered and have proper spacing */
        .about-us-image img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border: 1px solid #fff;
        }
    }
    
}

@media (max-width: 425px) {
    

    /* Remove absolute positioning and ensure proper scaling */
    .about-us-image {
        position: relative;
        width: 100%; /* Make the image take full width of its container */
        min-width: 300px; /* Optionally, you can add a maximum width */
        margin-bottom: 10px; /* Reduced margin to decrease the gap between images */
    }

    
}

